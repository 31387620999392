import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['imageStepperTalk'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageStepper = makeShortcode("ImageStepper");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "2-take-the-time-to-talk"
    }}>{`2. Take the time to talk`}</h1>
    <p>{`Good communication helps build a good relationship between you and your teenager. However, if your teenager doesn't like to talk, this can be hard. Here are some tips on talking with your teenager:`}</p>
    <h2 {...{
      "id": "firstly-be-available"
    }}>{`Firstly, be available…`}</h2>
    <ImageStepper id="imageStepperTalk" sequenceSpeed={2000} fadeSpeed={2000} list={[{
      src: '/images/m1/Note-1.svg',
      alt: 'talk advice 1'
    }, {
      src: '/images/m1/Note-2.svg',
      alt: 'talk advice 2'
    }, {
      src: '/images/m1/Note-3.svg',
      alt: 'talk advice 3'
    }, {
      src: '/images/m1/Note-4.svg',
      alt: 'talk advice 4'
    }, {
      src: '/images/m1/Note-5.svg',
      alt: 'talk advice 5'
    }, {
      src: '/images/m1/Note-6.svg',
      alt: 'talk advice 6'
    }]} mdxType="ImageStepper" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      